import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import ListaCarti from "./listacarti.js";
import Adaug from "./adaug.js";

const App = () => {
  const [lista, setLista] = useState([]);
  const [reaf, setReaf] = useState(false);

  const citesc = async () => {
    const obPromise = await fetch("http://localhost:5050/carti");
    const sirCarti = await obPromise.json();
    const listaNoua = sirCarti.map((item) => {
      item.coperta = "imagini/" + item.coperta;
      return item;
    });
    setLista(listaNoua); //  Utilizez datele primite
  };

  useEffect(() => {
    citesc();
  }, [reaf]);

  const adaug = async (carte) => {
    const { coperta, titlu, descriere, autor, pret } = carte;
    const formData = new FormData();
    formData.append("titlu", titlu);
    formData.append("descriere", descriere);
    formData.append("autor", autor);
    formData.append("pret", pret);
    formData.append("fisier", coperta);
    const config = {
      method: "POST",
      body: formData,
    };
    const obPromise = await fetch("http://localhost:5050/adaug", config);
    // const mesaj = await obPromise.json();
    setReaf(!reaf);
  };

  return (
    <>
      <Container>
        <h1>Carti pentru copii</h1>
      </Container>
      <ListaCarti listaCarti={lista} />
      <Container>
        <Adaug transmit={adaug} />
      </Container>
    </>
  );
};

export default App;
