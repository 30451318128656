import React, { useState, useRef } from "react";
import { Form, Container, Button } from "react-bootstrap";

const Adaug = (props) => {
  const ref = useRef();
  const [coperta, setCoperta] = useState();
  const [titlu, setTitlu] = useState("");
  const [descriere, setDescriere] = useState("");
  const [autor, setAutor] = useState("");
  const [pret, setPret] = useState("");

  const tratezSubmit = (evt) => {
    evt.preventDefault();
    const carte = { coperta, titlu, descriere, autor, pret };
    props.transmit(carte); //  Transmit spre <App /> obiectul carte
    //  Golesc controalele formularului
    setCoperta(null);
    ref.current.value = "";
    setTitlu("");
    setDescriere("");
    setAutor("");
    setPret("");
  };

  const handleFisierCoperta = (e) => {
    setCoperta(e.target.files[0]);
  };

  const stil = {
    marginTop: "2rem",
    backgroundColor: "#ddd",
    padding: "20px",
    width: "750px",
  };

  return (
    <Container style={stil}>
      <Form onSubmit={tratezSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Titlul:</Form.Label>
          <Form.Control
            type="text"
            value={titlu}
            onChange={(e) => setTitlu(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Coperta (maximum 1MB):</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => handleFisierCoperta(e)}
            ref={ref}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Descriere:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={descriere}
            onChange={(e) => setDescriere(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Autor:</Form.Label>
          <Form.Control
            type="text"
            value={autor}
            onChange={(e) => setAutor(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Preț:</Form.Label>
          <Form.Control
            type="text"
            value={pret}
            onChange={(e) => setPret(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Adaug;
